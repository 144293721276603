export default [{
  path: '/',
  async redirect(route, resolve) {
    const token = sessionStorage.getItem("token");
    if (token) {
      this.app.request.setup({
        headers: {
          Authorization: `${token}`
        }
      });
      try {
        const userData = await this.app.request.promise.json(`${process.env.API_URL}/admin/check-token`);
        sessionStorage.setItem('userData', JSON.stringify(userData.data));
        if (userData && userData.data) {
          resolve('/dashboard');
        } else {
          resolve('/login');
        }
      } catch (err) {
        resolve('/login');
      }
    } else {
      resolve('/login');
    }
  }
},

{
  path: '/menu',
  routes: [{
    path: '/cities',
    asyncComponent: import('@/menu/cities')
  }, {
    path: '/objects',
    asyncComponent: import('@/menu/objects')
  }, {
    path: '/billing',
    asyncComponent: import('@/menu/billing')
  }, {
    path: '/system',
    asyncComponent: import('@/menu/system')
  }]
},

{
  path: '/page',
  routes: [{
    path: '/documents',
    asyncComponent: import('@/pages/documents')
  }, {
    path: '/news',
    asyncComponent: import('@/pages/news')
  }, {
    path: '/moderate',
    asyncComponent: import('@/pages/moderate')
  }, {
    path: '/myObjects',
    asyncComponent: import('@/pages/myObjects')
  }, {
    path: '/myObjectsModerate',
    asyncComponent: import('@/pages/myObjectsModerate')
  }, {
    path: '/city/:id',
    asyncComponent: import('@/pages/city/index'),
    tabs: [{
      path: '/',
      id: 'news',
      asyncComponent: import('@/pages/city/news'),
    }, {
      path: '/newcomer',
      id: 'newcomer',
      asyncComponent: import('@/pages/city/newcomer'),
    }, {
      path: '/complex',
      id: 'complex',
      asyncComponent: import('@/pages/city/complex'),
    }, {
      path: '/directions',
      id: 'directions',
      asyncComponent: import('@/pages/city/directions'),
    }, {
      path: '/garage',
      id: 'garage',
      asyncComponent: import('@/pages/city/garage'),
    }, {
      path: '/districts',
      id: 'districts',
      asyncComponent: import('@/pages/city/districts'),
    }, {
      path: '/sadovod',
      id: 'sadovod',
      asyncComponent: import('@/pages/city/sadovod'),
    }]
  }, {
    path: '/category/:id',
    asyncComponent: import('@/pages/category/index'),
    tabs: [{
      path: '/',
      id: 'actual',
      asyncComponent: import('@/pages/category/actual'),
    }, {
      path: '/archive',
      id: 'archive',
      asyncComponent: import('@/pages/category/archive'),
    }, {
      path: '/draft',
      id: 'draft',
      asyncComponent: import('@/pages/category/draft'),
    }, {
      path: '/inactive',
      id: 'inactive',
      asyncComponent: import('@/pages/category/inactive'),
    }, {
      path: '/moderate',
      id: 'moderate',
      asyncComponent: import('@/pages/category/moderate'),
    }, {
      path: '/my',
      id: 'my',
      asyncComponent: import('@/pages/category/my'),
    }, {
      path: '/top',
      id: 'top',
      asyncComponent: import('@/pages/category/top'),
    }]
  }, {
    path: '/dictionary',
    asyncComponent: import('@/pages/dictionary')
  }, {
    path: '/log',
    asyncComponent: import('@/pages/log')
  }, {
    path: '/employee',
    asyncComponent: import('@/pages/employee/index'),
    tabs: [{
      path: '/',
      id: 'expert',
      asyncComponent: import('@/pages/employee/expert'),
    }, {
      path: '/agent',
      id: 'agent',
      asyncComponent: import('@/pages/employee/agent'),
    }, {
      path: '/broker',
      id: 'broker',
      asyncComponent: import('@/pages/employee/broker'),
    }, {
      path: '/photograph',
      id: 'photograph',
      asyncComponent: import('@/pages/employee/photograph'),
    }, {
      path: '/other',
      id: 'other',
      asyncComponent: import('@/pages/employee/other'),
    }]
  }, {
    path: '/user',
    asyncComponent: import('@/pages/user/index'),
    tabs: [{
      path: '/',
      id: 'active',
      asyncComponent: import('@/pages/user/active'),
    }, {
      path: '/banned',
      id: 'banned',
      asyncComponent: import('@/pages/user/banned')
    }]
  }, {
    path: '/mail',
    asyncComponent: import('@/pages/mail/index'),
    tabs: [{
      path: '/',
      id: 'pending',
      asyncComponent: import('@/pages/mail/pending'),
    }, {
      path: '/failed',
      id: 'failed',
      asyncComponent: import('@/pages/mail/failed'),
    }, {
      path: '/success',
      id: 'success',
      asyncComponent: import('@/pages/mail/success')
    }]
  }, {
    path: '/payments',
    asyncComponent: import('@/pages/payments')
  }, {
    path: '/price',
    asyncComponent: import('@/pages/price/index'),
    tabs: [{
      path: '/',
      id: 'buyer',
      asyncComponent: import('@/pages/price/buyer'),
    }, {
      path: '/seller',
      id: 'seller',
      asyncComponent: import('@/pages/price/seller'),
    }]
  }]
},

{
  path: '/popup',
  routes: [{
    path: '/category',
    popup: {
      asyncComponent: import('@/popups/category')
    }
  }, {
    path: '/category/:id',
    popup: {
      asyncComponent: import('@/popups/category')
    },
  }, {
    path: '/city',
    popup: {
      asyncComponent: import('@/popups/city')
    }
  }, {
    path: '/city/:id',
    popup: {
      asyncComponent: import('@/popups/city')
    }
  }, {
    path: '/complex',
    popup: {
      asyncComponent: import('@/popups/complex')
    }
  }, {
    path: '/complex/:id',
    popup: {
      asyncComponent: import('@/popups/complex')
    }
  }, {
    path: '/document',
    popup: {
      asyncComponent: import('@/popups/document')
    }
  }, {
    path: '/document/:id',
    popup: {
      asyncComponent: import('@/popups/document')
    }
  }, {
    path: '/employee',
    popup: {
      asyncComponent: import('@/popups/employee')
    }
  }, {
    path: '/employee/:id',
    popup: {
      asyncComponent: import('@/popups/employee')
    }
  }, {
    path: '/newcomer',
    popup: {
      asyncComponent: import('@/popups/newcomer')
    }
  }, {
    path: '/newcomer/:id',
    popup: {
      asyncComponent: import('@/popups/newcomer')
    }
  }, {
    path: '/news',
    popup: {
      asyncComponent: import('@/popups/news')
    }
  }, {
    path: '/news/:id',
    popup: {
      asyncComponent: import('@/popups/news')
    }
  }, {
    path: '/object',
    popup: {
      asyncComponent: import('@/popups/object')
    }
  }, {
    path: '/object/:id',
    popup: {
      asyncComponent: import('@/popups/object')
    }
  }, {
    path: '/moderation/:id',
    popup: {
      asyncComponent: import('@/popups/moderation')
    }
  }, {
    path: '/price',
    popup: {
      asyncComponent: import('@/popups/price')
    }
  }, {
    path: '/price/:id',
    popup: {
      asyncComponent: import('@/popups/price')
    }
  }, {
    path: '/user',
    popup: {
      asyncComponent: import('@/popups/user')
    }
  }, {
    path: '/user/:id',
    popup: {
      asyncComponent: import('@/popups/user')
    }
  }, {
    path: '/me',
    popup: {
      asyncComponent: import('@/popups/me')
    }
  }, {
    path: '/sadovod',
    popup: {
      asyncComponent: import('@/popups/sadovod')
    }
  }, {
    path: '/sadovod/:id',
    popup: {
      asyncComponent: import('@/popups/sadovod')
    }
  }, {
    path: '/garage',
    popup: {
      asyncComponent: import('@/popups/garage')
    }
  }, {
    path: '/garage/:id',
    popup: {
      asyncComponent: import('@/popups/garage')
    }
  }]
},

{
  path: '/dashboard',
  asyncComponent: import('@/pages/dashboard'),
  options: {
    animate: false
  }
},

{
  path: '/login',
  asyncComponent: import('@/pages/login'),
  options: {
    animate: false
  }
},

// Страница не найдена
{
  path: '(.*)',
  asyncComponent: import('@/pages/error')
}
]
