// Import Vue
import Vue from 'vue';

// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.less';

// Import App Component
import App from '../components/app.vue';
import f7Autocomplete from '../components/f7-autocomplete.vue';
import f7Uploader from '../components/f7-uploader.vue';
import f7Userbar from '../components/f7-userbar.vue';
import { VueMaskDirective } from 'v-mask';
import { VMoney } from 'v-money';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

Vue.component('f7-autocomplete', f7Autocomplete);
Vue.component('f7-uploader', f7Uploader);
Vue.component('f7-userbar', f7Userbar);

Vue.directive('mask', VueMaskDirective);
Vue.directive('money', VMoney);

// Init App
new Vue({
  el: '#app',
  render: (h) => h(App),

  // Register App Component
  components: {
    app: App
  },

  data() {
    return {
      isAuthorized: false,
      userData: JSON.parse(sessionStorage.getItem('userData'))
    }
  },

  methods: {
    hasRights(access) {
      return this.userData && this.userData.rights && this.userData.rights[access];
    },

    async getJSON(url, data) {
      try {
        const result = await Framework7.request.promise.json(`${process.env.API_URL}/admin/${url}`, data);
        return result.data;
      } catch ({ xhr }) {
        throw JSON.parse(xhr.response);
      }
    },

    async postJSON(url, data) {
      try {
        const result = await Framework7.request.promise.postJSON(`${process.env.API_URL}/admin/${url}`, data);
        return result.data;
      } catch ({ xhr }) {
        throw JSON.parse(xhr.response);
      }
    },

    async uploadFile(files) {
      try {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          formData.append('files[]', files[i]);
        }
        const result = await this.$f7.request.promise({
          url: `${process.env.API_URL}/admin/upload`,
          method: "POST",
          data: formData,
          cache: false,
          dataType: "application/json",
          crossDomain: true,
          contentType: "multipart/form-data",
          processData: true
        });
        return JSON.parse(result.data);
      } catch ({ xhr }) {
        throw JSON.parse(xhr.response);
      }
    }
  }
});
