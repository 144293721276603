<template lang="pug">
f7-app.color-theme-teal(:params="f7params", :class="isDev ? 'dev-marker' : ''")
  f7-panel(left, cover, :visibleBreakpoint="720", v-if="$root.isAuthorized")
    f7-view(:animate="false", @view:init="watchCounters"): f7-page
      f7-navbar(title="Панель управления")

      f7-userbar

      f7-list
        f7-list-item(link="/menu/cities", title="Населённые пункты", footer="Доступные для выбора города", v-if="$root.hasRights('menuCity')", @click="selectedId = undefined")
          f7-icon(slot="media", f7="placemark")
        f7-list-item(link="/menu/objects", title="Недвижимость", footer="Все объекты недвижимости", v-if="$root.hasRights('menuCategory')", @click="selectedId = undefined")
          f7-icon(slot="media", f7="house")
        f7-list-item(view=".view-main", panel-close, link="/page/myObjects", title="Мои объекты", footer="Назначенные мне объекты", v-if="$root.hasRights('myObjects')", no-chevron, :class="{ 'selected': selectedId === 'myObjects' }", @click="selectedId = 'myObjects'")
          f7-icon(slot="media", f7="briefcase")
        f7-list-item(view=".view-main", panel-close, link="/page/moderate", title="Модерация", footer="Общая очередь модерации", v-if="$root.hasRights('moderateObjects')", no-chevron, :class="{ 'selected': selectedId === 'moderate' }", @click="selectedId = 'moderate'", :badge="counterModerate", badge-color="orange")
          f7-icon(slot="media", f7="plus_slash_minus")
        f7-list-item(view=".view-main", panel-close, link="/page/myObjectsModerate", title="Модерация моих объектов", footer="Мои объекты на модерации", v-if="$root.hasRights('moderateMyObjects')", no-chevron, :class="{ 'selected': selectedId === 'myObjectsModerate' }", @click="selectedId = 'myObjectsModerate'", :badge="counterMyModerate", badge-color="orange")
          f7-icon(slot="media", f7="metronome")
        f7-list-item(view=".view-main", panel-close, link="/page/documents", title="Документы", footer="Публичные страницы сайта", v-if="$root.hasRights('document')", no-chevron, :class="{ 'selected': selectedId === 'documents' }", @click="selectedId = 'documents'")
          f7-icon(slot="media", f7="square_pencil")
        f7-list-item(view=".view-main", panel-close, link="/page/news", title="Общие новости", footer="Новости для всех городов", v-if="$root.hasRights('news')", no-chevron, :class="{ 'selected': selectedId === 'news' }", @click="selectedId = 'news'")
          f7-icon(slot="media", f7="speaker_2")
        f7-list-item(link="/menu/billing", title="Онлайн-касса", footer="Ценообразование и отчёты", v-if="$root.hasRights('menuBilling')", @click="selectedId = undefined")
          f7-icon(slot="media", f7="money_rubl_circle")
        f7-list-item(link="/menu/system", title="Система", footer="Общие параметры сайта", v-if="$root.hasRights('menuSystem')", @click="selectedId = undefined")
          f7-icon(slot="media", f7="bolt")

      f7-block.text-align-center
        f7-block-footer Выберите интересующий Вас раздел.

      f7-toolbar(bottom, labels)
        f7-link(view=".view-main", href="/login", icon-f7="power", color="red") Выйти

  f7-view.safe-areas(main, url="/")
</template>

<script>
  import routes from "../js/routes.js";

  export default {
    data() {
      return {
        isDev: process?.env?.DEV_MARKER === "true",
        selectedId: undefined,
        counterModerate: null,
        counterMyModerate: null,
        f7params: {
          id: "cloud.seon.app.yaskpcp",
          name: "Панель управления ЯСКП",
          theme: "aurora",
          locale: "ru",
          routes: routes,

          dialog: {
            buttonCancel: "Отмена",
            usernamePlaceholder: "Имя пользователя",
            passwordPlaceholder: "Пароль",
            preloaderTitle: "Подождите...",
            progressTitle: "Подождите...",
          },

          popup: {
            closeByBackdropClick: false,
          },

          calendar: {
            closeOnSelect: true,
            formatValue(value) {
              if (value) {
                return value
                  .map((v) => {
                    return this.formatDate(v);
                  })
                  .join(this.params.rangePicker ? " - " : ", ");
              }
              return "";
            },
          },

          view: {
            transition: "f7-fade",
          },
        },
      };
    },

    methods: {
      async getCounters() {
        try {
          const data = await this.$root.getJSON('object', {
            query: {
              city: undefined,
              objectStatus: "published",
              moderationStatus: "pending",
            }
          });
          this.counterModerate = data.total;

          const myData = await this.$root.getJSON('object', {
            query: {
              city: undefined,
              objectStatus: "published",
              moderationStatus: "pending",
              $or: [{ agent: this.$root.userData._id }, { expert: this.$root.userData._id }, { broker: this.$root.userData._id }],
            }
          });
          this.counterMyModerate = myData.total;
        } catch (err) {}
      },

      watchCounters() {
        console.log('watch counters');
        this.getCounters();
        setInterval(this.getCounters, 60000);
      }
    }
  };
</script>
