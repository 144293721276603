<template lang="pug">
f7-list-input.button-right(:id="id", :input="false", :label="label", :disabled="disabled")
  div(slot="input")
    input(type="text", :value="displayText", autocomplete="false", :placeholder="placeholder", :readonly="select", :disabled="disabled", :required="required", :data-validate="validate")
  .display-flex.align-items-center(slot="inner")
    f7-link.margin-right-half.clear-button(icon-size="17px", icon-only, icon-f7="xmark_circle_fill", @click="clear", v-if="clearable && value")
    f7-link(:icon-f7="isOpened ? 'chevron_up' : 'chevron_down'", @click="toggleOpen")
  div(slot="inner", v-if="editor")
    f7-button.padding-horizontal-half.margin-left-half(icon-f7="plus", title="Добавить", :href="typeof editor === 'string' ? editor : undefined", @click="typeof editor === 'function' ? editor() : undefined")
</template>

<style lang="less" scoped>
  .clear-button {
    color: var(--f7-input-clear-button-color);
  }
</style>

<script>
  export default {
    props: {
      label: String,
      placeholder: String,
      editor: [String, Function],
      source: Object,
      select: Boolean,
      disabled: Boolean,
      value: [String, Number, Object],
      required: Boolean,
      validate: Boolean,
      returnObject: Boolean,
      clearable: {
        type: Boolean,
        default: true,
      },
    },

    data() {
      return {
        id: `autocomplete_${this.$utils.id()}`,
        displayText: "",
        isOpened: false,
      };
    },

    watch: {
      value: {
        immediate: true,
        handler(value) {
          this.setDisplayText(value);
        },
      },
    },

    methods: {
      toggleOpen() {
        if (this.isOpened) {
          this.$f7.autocomplete.close(`#${this.id} input[type=text]`);
        } else {
          this.$f7.autocomplete.open(`#${this.id} input[type=text]`);
        }
      },

      clear() {
        this.displayText = "";
        this.$emit("change", null);
        this.$f7.autocomplete.close(`#${this.id} input[type=text]`);
      },

      async setDisplayText(value) {
        if (value && typeof value === "object") {
          value = value[this.source.idProperty];
        }
        if (value) {
          try {
            if (this.source.items) {
              const item = this.source.items.find((item) => item[this.source.idProperty] === value);
              if (item) {
                this.displayText = item[this.source.displayProperty];
              }
            } else {
              let queryData = {
                ...(this.source.query || {}),
              };
              queryData[this.source.idProperty] = value;
              const responseData = await this.$root.getJSON(`${this.source.endpoint}`, {
                pageSize: 50,
                sort: this.source.sort || "title",
                query: {
                  ...queryData,
                  isDeleted: { $ne: true },
                },
              });
              const items = responseData.rows;
              if (items && items.length) {
                this.displayText = items[0][this.source.displayProperty];
              }
            }
          } catch (error) {
            console.dir(error);
          }
        }
      },
    },

    mounted() {
      const self = this;

      this.$f7.autocomplete.create({
        inputEl: `#${this.id} input[type=text]`,
        openIn: "dropdown",
        preloader: true,
        valueProperty: self.source.idProperty || "_id",
        textProperty: self.source.displayProperty || "title",
        updateInputValueOnSelect: false,
        limit: 50,
        value: [this.value],
        async source(query, render) {
          this.preloaderShow();
          let items = [];
          try {
            if (self.source.items) {
              if (this.isOpened) {
                items = self.source.items.filter((item) => item[self.source.displayProperty].toLowerCase().indexOf(query.toLowerCase()) !== -1);
              } else {
                items = self.source.items;
              }
            } else {
              let queryData = {
                ...(self.source.query || {}),
              };
              if (this.isOpened) {
                queryData[self.source.displayProperty] = {
                  $regex: query,
                  $options: "i",
                };
              }
              const responseData = await self.$root.getJSON(`${self.source.endpoint}`, {
                pageSize: 50,
                sort: self.source.sort || "title",
                query: {
                  ...queryData,
                  isDeleted: { $ne: true },
                },
              });
              items = responseData.rows;
            }
          } catch (error) {
            console.dir(error);
          }
          const res = items
            .filter((i) => i.isDeleted !== true)
            .sort((a, b) => {
              if (a[self.source.sort || "title"] > b[self.source.sort || "title"]) {
                return 1;
              } else if (a[self.source.sort || "title"] < b[self.source.sort || "title"]) {
                return -1;
              } else return 0;
            });
          render(res);
          this.preloaderHide();
        },

        on: {
          change(value) {
            self.$emit("change", value ? (self.returnObject ? value[0] : value[0][self.source.idProperty]) : null);
          },
          opened() {
            self.isOpened = true;
            this.isOpened = true;
          },
          closed() {
            self.isOpened = false;
            this.isOpened = false;
          },
        },
      });
    },
  };
</script>
