import moment from "moment";

export const pageData = {
  items: [],
  filter: {},
  dataSource: {},
  search: ''
}

// Первоначальная загрузка данных страницы
export async function load() {
  this.dataSource.loading = true;
  if (this.onBeforeLoad && typeof this.onBeforeLoad === 'function') {
    try {
      await this.onBeforeLoad();
    } catch (err) { }
  }
  try {
    this.dataSource.page = 1;
    const queryParams = {
      sort: this.dataSource.sort || 'title',
      query: {
        isDeleted: { $ne: true }
      }
    };
    if (this.dataSource.fields) {
      queryParams['fields'] = this.dataSource.fields;
    }
    if (this.dataSource.populate) {
      queryParams['populate'] = this.dataSource.populate;
    }
    if (this.dataSource.search) {
      queryParams['search'] = this.dataSource.search;
    }
    if (this.dataSource.searchFields) {
      queryParams['searchFields'] = this.dataSource.searchFields;
    }
    if (this.dataSource.query) {
      queryParams['query'] = { ...queryParams['query'], ...this.dataSource.query };
    }
    const data = await this.$root.getJSON(`${this.dataSource.endpoint}`, queryParams);
    this.items = data.rows;
    this.dataSource.totalPages = data.totalPages;
    this.dataSource.total = data.total;
  } catch (err) {
    this.$f7.dialog.alert(err.message, "Ошибка при загрузке данных");
  }
  if (this.onAfterLoad && typeof this.onAfterLoad === 'function') {
    try {
      await this.onAfterLoad();
    } catch (err) { }
  }
  this.dataSource.loading = false;
}

// Догрузка данных страницы при прокрутке
export async function loadMore() {
  if (!this.dataSource.loading && this.dataSource.page < this.dataSource.totalPages) {
    this.dataSource.loading = true;
    try {
      const queryParams = {
        sort: this.dataSource.sort || 'title',
        query: {
          isDeleted: { $ne: true }
        }
      };
      if (this.dataSource.fields) {
        queryParams['fields'] = this.dataSource.fields;
      }
      if (this.dataSource.populate) {
        queryParams['populate'] = this.dataSource.populate;
      }
      if (this.dataSource.search) {
        queryParams['search'] = this.dataSource.search;
      }
      if (this.dataSource.searchFields) {
        queryParams['searchFields'] = this.dataSource.searchFields;
      }
      if (this.dataSource.query) {
        queryParams['query'] = { ...queryParams['query'], ...this.dataSource.query };
      }
      const data = await this.$root.getJSON(`${this.dataSource.endpoint}`, {
        ...queryParams,
        page: this.dataSource.page + 1
      });
      const ids = new Set(this.items.map(item => item._id));
      this.items = [...this.items, ...data.rows.filter(item => !ids.has(item._id))];
      this.dataSource.totalPages = data.totalPages;
      this.dataSource.page = data.page;
      this.dataSource.total = data.total;
    } catch (err) {
      this.$f7.dialog.alert(err.message, "Ошибка при подгрузке данных");
    }
    this.dataSource.loading = false;
  }
}

export async function save() {
  const isValid = this.$f7.input.validateInputs(this.$el);
  if (!isValid) {
    this.$f7.dialog.alert("Некорректно заполнена форма.<br/>Проверьте отмеченные поля.", "Ошибка при сохранении");
    return;
  }

  try {
    let item = null;
    if (this.form._id) {
      item = await this.$root.postJSON(`${this.dataSource.endpoint}/${this.form._id}`, this.form);
      this.$f7.emit(`${this.dataSource.endpoint}:update`, item);
    } else {
      item = await this.$root.postJSON(`${this.dataSource.endpoint}`, this.form);
      this.$f7.emit(`${this.dataSource.endpoint}:create`, item);
    }
    this.$f7.popup.close();
    return item;
  } catch (err) {
    this.$f7.dialog.alert(err.message, "Ошибка при сохранении");
  }
}

export async function saveWithoutClose() {
  const isValid = this.$f7.input.validateInputs(this.$el);
  if (!isValid) {
    this.$f7.dialog.alert("Некорректно заполнена форма.<br/>Проверьте отмеченные поля.", "Ошибка при сохранении");
    return;
  }

  try {
    let item = null;
    if (this.form._id) {
      item = await this.$root.postJSON(`${this.dataSource.endpoint}/${this.form._id}`, this.form);
      this.$f7.emit(`${this.dataSource.endpoint}:update`, item);
    } else {
      item = await this.$root.postJSON(`${this.dataSource.endpoint}`, this.form);
      this.$f7.emit(`${this.dataSource.endpoint}:create`, item);
    }
    this.$f7.dialog.alert("Данные сохранены", "Внимание!");
    return item;
  } catch (err) {
    this.$f7.dialog.alert(err.message, "Ошибка при сохранении");
  }
}

export async function remove() {
  this.$f7.dialog.confirm(
    "Действие необратимо, передумать потом будет поздно.",
    "Подтвердите удаление",
    async () => {
      try {
        if (this.form._id) {
          const item = await this.$root.postJSON(`${this.dataSource.endpoint}/${this.form._id}`, {
            ...this.form,
            isDeleted: true
          });
          this.$f7.emit(`${this.dataSource.endpoint}:remove`, item);
          this.$f7.popup.close();
          return item;
        }
      } catch (err) {
        this.$f7.dialog.alert(err.message, "Ошибка при удалении");
      }
    }
  );
}

export async function upload(event, multiple = false) {
  if (event && event.target) {
    const selection = event.target.files;
    let result = null;
    if (selection && selection.length) {
      const progress = this.$f7.dialog.progress();
      try {
        result = await this.$root.uploadFile(selection);
        progress.close();
      } catch (err) {
        progress.close();
        this.$f7.dialog.alert(err.message, "Ошибка при загрузке файла");
      }
      event.target.value = null;
    }
    return multiple ? result : result[0];
  }
}

export function formatDate(value, format = "DD.MM.YYYY") {
  return moment(value).format(format);
}

export function toISO(value) {
  if (value) {
    return moment(value).format();
  }
  return null;
}

export function debounce(f, ms) {
  let isCooldown = false;
  return function () {
    if (isCooldown) return;
    f.apply(this, arguments);
    isCooldown = true;
    setTimeout(() => isCooldown = false, ms);
  };
}

export function onClosed() {
  this.$destroy();
}
