<template lang="pug">
.relative
  f7-card.no-padding.no-margin(:style="{ height: height || 'auto', 'background-image': 'url(' + (image ? image.links[size] : defaultImage) + ')' }"): div(:class="aspect ? `aspect-${aspect}` : ''"): .inner
    f7-row.buttonContainer.justify-content-center.align-content-center
      f7-col(width="50")
        label.button.button-round.button-fill.button-large.button-raised.color-orange(:for="id") Загрузить
        input.display-none(:id="id", type="file", @change="upload")
</template>

<style lang="less" scoped>
  .card {
    font-size: 0;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
  }

  .aspect {
    &-1x1 {
      padding-top: 100% !important;
    }
    &-16x9 {
      padding-top: 56.25% !important;
    }
  }

  .relative {
    position: relative;
  }

  .inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .imageContainer {
    width: 100%;
    object-fit: cover;
  }

  .buttonContainer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    label {
      opacity: 0;
    }

    &:hover label {
      opacity: 1;
    }
  }
</style>

<script>
  import * as common from "@/js/common";

  export default {
    props: ["defaultImage", "value", "size", "aspect", "height"],

    data() {
      return {
        id: `uploader_${this.$f7.utils.id()}`,
        image: this.value,
      };
    },

    watch: {
      value: {
        immediate: true,
        handler(val, oldVal) {
          this.image = val;
        },
      },
    },

    methods: {
      async upload(event) {
        try {
          this.image = await common.upload.call(this, event);
          this.$emit("change", this.image);
        } catch (err) {
          this.$f7.dialog.alert(err.message, "Ошибка при загрузке файла");
        }
      },
    },
  };
</script>
